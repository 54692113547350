<template>
    <div>
        <b-navbar toggleable="xl" variant="light" sticky>
            <b-navbar-brand :to="homePath">
                <img src="@/assets/logo.svg" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <div v-for="(link, index) in links" :key="index">
                        <b-nav-item v-if="link.value" @click="$router.push(link.value)">{{ link.text }}</b-nav-item>
                    </div>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right>
                        <template #button-content>{{ merchantName }}</template>
                        <b-dropdown-item disabled>版本 v{{ version }}</b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item @click="logout">登出</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <router-view />
    </div>
</template>

<script>
import packageJson from '../../package.json'

export default {
    data() {
        return {
            homePath: null,
            merchantName: null,
        }
    },

    created() {
        this.merchantName = localStorage.merchantName
        this.homePath = localStorage.authType == 'admin' ? '/godDashboard' : '/dashboard'
    },

    computed: {
        version() {
            return packageJson.version
        },

        links() {
            if (localStorage.authType == 'admin') return [
                {
                    text: '商戶',
                    value: '/merchants',
                },
            ]
            else return [
                {
                    text: '分店',
                    value: '/shops',
                },
                {
                    text: '商品分類',
                    value: '/categories',
                },
                {
                    text: '商品',
                    value: '/products',
                },
                {
                    text: '選項分類',
                    value: '/extras',
                },
                {
                    text: '選項',
                    value: '/items',
                },
                {
                    text: '折扣',
                    value: '/discounts',
                },
                {
                    text: '客戶',
                    value: '/customers',
                },
                {
                    text: '訂單',
                    value: '/orders',
                },
                {
                    text: '開支分類',
                    value: '/expenseCategories',
                },
                {
                    text: '開支',
                    value: '/expenses',
                },
                {
                    text: '商品報告',
                    value: '/productsReport',
                },
                {
                    text: '銷售報告',
                    value: '/salesReport'
                },
            ]
        }
    },

    methods: {
        logout() {
            localStorage.isAuth = false
            localStorage.removeItem('authType')
            localStorage.removeItem('merchantId')
            localStorage.removeItem('merchantName')

            const states = [
                'items',
                'extras',
                'products', 
                'categories',
                'customers',
                'discounts',
                'shops',
                'orders',
            ]
            states.forEach(state => {
                this.$store.dispatch('unbindState', { item: state })
            })
            return this.$router.push('/login')
        }
    }
}
</script>